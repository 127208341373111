/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-12-26 17:10:16
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-08-09 15:20:47
 */
let baseUrl = '';
const env = import.meta.env;
if (env.MODE === 'development') {
    baseUrl = `http://www.yky.com`; // 开发环境地址
} else if (env.MODE === 'production') {
    baseUrl = `https://api.ukoocloud.com`; // 生产环境地址
} else if (env.MODE === 'test') {
    baseUrl = `https://ceshi.ukoocloud.com`; // 测试环境地址
}

let ossUrl = 'https://ukoo.oss-cn-beijing.aliyuncs.com/';

export {
    baseUrl,
    ossUrl,
    env
}
