/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2024-03-07 10:12:24
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-08-19 11:15:12
 */
import axios from "axios";
import {baseUrl} from "@/config/env";
import router from "@/router";
import {getStore} from "@/utils/util";
  
// 超时时间
axios.defaults.timeout = 80000;

// 添加请求拦截器
axios.interceptors.request.use(config => {
    // 在发送请求之前要做的处理
    return config;
}, error => {
    console.log(error);
    // 对请求错误情况的处理
    // return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(response => {
    // 对响应数据做点什么
    return response;
}, error => {
    if (error.message === 'timeout of 20000ms exceeded'){
        // 对响应错误做点什么
        return Promise.reject({code: 400, msg: '请求超时'});
    }
    if (error.code === 401) {
        window.location.reload();
        router.push('/login')
    }
});

// 请求初始化
const instance = axios.create({
    baseURL: baseUrl,
    headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded',
        'Content-Type': 'application/json',
        'Platform': 2
    }
});

/**
 * POST方法
 * @param url
 * @param params
 * @param headers
 * @returns {Promise<unknown>}
 */
const post = (url, params, headers = {}) => {
    return new Promise((resolve, reject) => {
        let token = getStore({name: 'token'});
        if (token) headers.Authorization = `Bearer ${token}`;
        instance.post(url, params, {
            headers: headers
        })
            .then(res => {
                if (!res) return Promise.reject({code: 400, msg: '数据为空'});
                if (res.data.code === 401) return router.push({path: '/login'});
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });
    });
}

/**
 * GET方法
 * @param url
 * @param params
 * @param headers
 * @returns {Promise<unknown>}
 */
const get = (url, params, headers = {}) => {
    return new Promise((resolve, reject) => {
        if (headers === undefined) headers = {};
        let token = getStore({name: 'token'});
        if (token) headers.authorization = `Bearer ${token}`;
        instance.get(url, {
            params: params,
            headers: headers
        })
            .then(res => {
                if (!res) return Promise.reject({code: 400, msg: '数据为空'});
                if (res.data.code === 401) return router.push({path: '/login'});
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });
    });
}

export {
    post, get
}
