/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2024-03-07 10:12:24
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-06-28 15:48:47
 */
import {createStore} from 'vuex'
import Cookies from "js-cookie";
import router from "@/router";
export default createStore({
    state:{
        isCollapse:true,
        currentMenu:null,
        tabsList:[
            {
                path:'/',
                name:'home',
                label:'首页',
                icon:'home',
            }
        ],
        menu:[],
        token:null,
        keepAliveComponents: [],
    },
    mutations:{
        // 添加组件到缓存列表
        addCacheComponent(state, componentName) {
            console.log(componentName);
            if (!state.keepAliveComponents.includes(componentName)) {
                state.keepAliveComponents.push(componentName);
            }
        },
        // 从缓存列表中移除组件
        removeCacheComponent(state, componentName) {
            const index = state.keepAliveComponents.indexOf(componentName);
            if (index > -1) {
                state.keepAliveComponents.splice(index, 1);
            }
        },
        updateIsCollapse(state,payload){
            console.log(payload);
            state.isCollapse = !state.isCollapse;
        },
        // 跳转页面
        refreshto(state, payload){
            const { url, data } = payload;
            router.push({
                name: url,
                query: data
            });
        
            let menuList = state.menu
            let obj = {}
            menuList.forEach(item =>{
                item.menu.forEach(item2 =>{
                    item2.list.forEach(item3 => {
                        if(item3.name === url){
                            obj = item3
                        }
                    })
                })
            })
            this.commit('selectMenu',obj);
        },
        // 关闭tab
        closeTab(state,val){
            let res = state.tabsList.findIndex(item =>item.name === val.name);
            state.tabsList.splice(res,1);
        },
        // 关闭tab
        closeAllTab(state){
            const tabsToRemove = [];
            state.tabsList.forEach(v => {
                if (v.name!= 'home') {
                    tabsToRemove.push(v);
                }
            });
            tabsToRemove.forEach(v => {
                state.tabsList = state.tabsList.filter(tab => tab.name!= v.name);
            });
        },
        selectMenu(state,val){
            //判断
            // val.name==='home' ? (state.currentMenu = null) : (state.currentMenu = val)
            if (val.name==='home'){
                state.currentMenu = null
            }else {
                state.currentMenu = val;
                let result = state.tabsList.findIndex(item => item.name === val.name);
                result === -1 ?state.tabsList.splice(1, 0, val) : '';
                // result === -1 ?state.tabsList.push(val) : '';
            }
        },

        setMenu(state,val){  //Login.Vue
            state.menu = val;
            localStorage.setItem('menu',JSON.stringify(val))
        },

        addMenu(state,router){
            if (!localStorage.getItem('menu')){
                return
            }
            const menu = JSON.parse(localStorage.getItem('menu'))
            state.menu = menu;

            const modules = import.meta.glob("../views/**/**.vue");

            //动态路由
            const menuArray =[];
            menu.forEach(item =>{
                
                item.menu.forEach(item2 =>{
                    item2.list.forEach(item3 => {
                        let url = `../views/${item3.url}.vue`;
                        /* @vite-ignore */
                        item3.component = modules[url];
                        menuArray.push(item3);
                    })
                })
            })

            menuArray.forEach(item =>{
                router.addRoute('home1',item);
            })
        },

        cleanMenu(state){
            state.menu =[]
            localStorage.removeItem('menu');
        },

        setToken(state,val){
            state.token =val;
            Cookies.set('token',val);
        },
        clearToken(state){
            state.token ='';
            Cookies.remove('token');
        },
        getToken(state){
            state.token = state.token || Cookies.get("token");
        }

    }
})