/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-12-26 15:56:04
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-07-16 16:40:21
 */
import { createApp } from 'vue'

// import './style.css'

import App from './App.vue'
import router from './router'
import store from './stores'
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import locale from 'element-plus/es/locale/lang/zh-cn';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// 为了使自己的样式优先级更高，尽量将导入往后放
import '@/assets/main.scss'
import {specImg} from "@/utils/util";



const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
store.commit('addMenu',router);


// router.beforeEach((to,from,next) =>{
//     store.commit('getToken')
//     const token = store.state.token
//     if (!token && to.name !== 'login'){
//         console.log("运行到了这里");
//         next({name:'login'});
//     }
//     else {
//         next();
//     }
// })

app.config.globalProperties.$specImg = specImg;


app.use(store)
app.use(router)
app.use(ElementPlus, {locale});

app.mount('#app')

