/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2024-03-05 16:37:48
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-05-30 11:07:16
 */

import {
  createRouter,
  createWebHashHistory
} from 'vue-router'

import { getStore } from "@/utils/util";

const routes = [
  {
    path:'/',
    component:()=>import('../views/Main.vue'),
    name:'home1',
    redirect:'/home',
    children:[{
          path: '/home',
          name: 'home',
          component: () => import('../views/home/Home.vue')
      },
    ]
  },
  {
      path:'/login',
      name:'login',
      component:()=>import ("../views/Login.vue"),
  },
  {
    path:'/look',
    name:'look',
    component:()=>import ("../views/Look.vue"),
},
]

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes
})

// 导航卫士
router.beforeEach((to) => {
  // 登录访问拦截=>默认是直接放行的
  // 根据返回值决定，是放行还是拦截
  const token = getStore({name: 'token'});
  if (!token && to.path !== '/login') {
    ElMessage('你还没有登录，请先登录')
    return '/login'
  }
  return true
})

export default router