import moment from "moment/moment";
import * as http from "@/utils/http";
import {ossUrl} from "@/config/env";
import axios from "axios";


  /**
   * 解析 元素的innerHTML内容即为转义后的字符
   * @param str
   * @returns {string}
   */
export const htmlDecode = (str) => {
    return str.replace(/&(amp|gt|lt|quot|#39|nbsp);/g, (a) => {
        return {
        "&lt;": "<",
        "&amp;": "&",
        "&quot;": '"',
        "&gt;": ">",
        "&#39;": "'",
        "&nbsp;": " ",
        }[a];
    });

}
/**
 * 判断是否为空
 */
export function validateNull(val) {
    if (typeof val == 'boolean') {
        return false;
    }
    if (typeof val == 'number') {
        return false;
    }
    if (val instanceof Array) {
        if (val.length === 0) return true;
    } else if (val instanceof Object) {
        if (JSON.stringify(val) === '{}') return true;
    } else {
        return val === 'null' || val == null || val === 'undefined' || val === undefined || val === '';
    }
    return false;
}

/**
 * 存储localStorage
 */
export const setStore = (params = {}) => {
    let {
        name,
        content,
        type,
    } = params;
    let obj = {
        dataType: typeof (content),
        content: content,
        type: type,
        datetime: new Date().getTime()
    }
    if (type) window.sessionStorage.setItem(name, JSON.stringify(obj));
    else window.localStorage.setItem(name, JSON.stringify(obj));
}

/**
 * 获取localStorage
 */
export const getStore = (params = {}) => {
    let {
        name,
        debug
    } = params;
    let obj = {},
        content;
    obj = window.sessionStorage.getItem(name);
    if (validateNull(obj)) obj = window.localStorage.getItem(name);
    if (validateNull(obj)) return;
    try {
        obj = JSON.parse(obj);
    } catch (e) {
        return obj;
    }
    if (debug) {
        return obj;
    }
    if (obj.dataType === 'string') {
        content = obj.content;
    } else if (obj.dataType === 'number') {
        content = Number(obj.content);
    } else if (obj.dataType === 'boolean') {
        content = eval(obj.content);
    } else if (obj.dataType === 'object') {
        content = obj.content;
    }
    return content;
}

/**
 * 删除localStorage
 */
export const removeStore = (params = {}) => {
    let {
        name,
        type
    } = params;
    if (type) {
        window.sessionStorage.removeItem(name);
    } else {
        window.localStorage.removeItem(name);
    }

}

/**
 * 获取全部localStorage
 */
export const getAllStore = (params = {}) => {
    let list = [];
    let {
        type
    } = params;
    if (type) {
        for (let i = 0; i <= window.sessionStorage.length; i++) {
            list.push({
                name: window.sessionStorage.key(i),
                content: getStore({
                    name: window.sessionStorage.key(i),
                    type: 'session'
                })
            })
        }
    } else {
        for (let i = 0; i <= window.localStorage.length; i++) {
            list.push({
                name: window.localStorage.key(i),
                content: getStore({
                    name: window.localStorage.key(i),
                })
            })

        }
    }
    return list;

}

/**
 * 清空全部localStorage
 */
export const clearStore = (params = {}) => {
    let {type} = params;
    if (type) {
        window.sessionStorage.clear();
    } else {
        window.localStorage.clear()
    }
}

/**
 * 节流操作
 * @param fn
 * @param delay
 * @returns {(function(...[*]): void)|*}
 */
export const throttle = function (fn, delay) {
    // 缓存一个定时器
    let timer = null;
    // 这里返回的函数是每次用户实际调用的节流函数
    return () => {
        if (!timer) { // 判断timer是否有值,如果没有则说明定时器不存在即可继续执行
            timer = setTimeout(() => { // 关
                fn.apply(this, arguments);
                timer = null; // 开
            }, delay);
        }
    }
}

/**
 * 防抖操作
 * @param fn
 * @param delay
 * @returns {(function(): void)|*}
 */
export const debounce = function (fn, delay) {
    let timer = null;
    return () => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            fn.apply(this, arguments);
        }, delay);
    }
}

/**
 * 获取规格图 不存在则取主图
 * @param spec_img
 * @param img
 * @returns {*}
 */
export const specImg = (spec_img, img) => {
  if (spec_img === '' || spec_img === 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20231127/7da2b47a628a9949369cecc5ffccc77e.jpg'){
      return img;
  } else {
      return spec_img;
  }
}

/**
 * UUID
 * @returns {string}
 */
export const uuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
    });
}

/**
 * OSS-前端秘钥直传
 * @param file_name
 * @param param
 * @returns {Promise<unknown>}
 */
export const ossUp = (file_name, param) => {
    return new Promise((resolve, reject) => {
        http.post('/api/common/get_oss_token', {
            file_name: file_name,
        }).then(res => {
            if (res.code === 200) {
                let data = res.data.data;
                let file = param.file;

                let ymd = moment().format('YYYYMMDD');
                let accessUrl = data.dir + '/' + ymd + '/' + uuid() + file.name; // 设置上传的访问路径

                let sendData = new FormData(); // 上传文件的data参数
                sendData.append('OSSAccessKeyId', data.accessid);
                sendData.append('policy', data.policy);
                sendData.append('Signature', data.signature);
                sendData.append('keys', data.dir);
                sendData.append('key', accessUrl); // 上传的文件路径
                sendData.append('success_action_status', 200); // 指定返回的状态码
                sendData.append('type', 'image/jpeg');
                sendData.append('file', file);

                let headers = {
                    'Content-Type': 'application/form-data',
                };

                http.post(data.host, sendData, headers).then(() => {
                    resolve(ossUrl + accessUrl);
                }).catch(() => {
                    reject('上传失败');
                });
            } else {
                reject(res.msg);
            }
        });
    });
}

export const printPdf = (url, param) => {
    const loading = ElLoading.service({
        text: '加载中',
        background: 'rgba(122, 122, 122, 0.8)'
    });
    http.post(url, param).then(res => {
        loading.close();
        if (res.code === 200) {
            // 获取的PDF链接
            let pdfUrl = ossUrl + res.data.pdf_url;

            axios({
                url: pdfUrl,
                method: 'get',
                responseType: 'blob',
            }).then((response) => {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);

                // 创建一个隐藏的 iframe
                const iframe = document.createElement('iframe');
                iframe.style.display = 'none';
                iframe.src = url;
                document.body.appendChild(iframe);

                // 等待 iframe 加载完成
                iframe.onload = function() {
                    // 触发打印命令
                    iframe.contentWindow.print();

                    // 释放 Blob URL
                    window.URL.revokeObjectURL(url);
                };
            }).catch(error => {
                ElMessage({
                    message: error,
                    type: 'warning'
                });
            });
        } else {
            ElMessage({
                message: res.msg,
                type: 'warning'
            });
        }
    });
}